.get-started-page {

  #logo {
    color: #6e3cc3;
    img {
      padding-right: 5px;
    }
  }

}

