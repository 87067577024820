.api-documentation {

  #logo {
    color: #6e3cc3;

    img {
      padding-right: 5px;
    }
  }

  .swagger-ui .info {
    margin: 15px 0;
  }

}

.swagger-ui {
  .wrapper {
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
  }

}