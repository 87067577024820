.quickt__header {
    background-color: #fff;
    border-bottom: 1px solid #dadada;
    padding: 0;
    z-index: 12;
    width: 100%;

    .active {
        background-color: #e5e7eb;
    }

    .dropdown {
        min-width: 150px;
        margin-right: 10px;
    }
}